'use strict';
import { Swiper } from 'swiper'
import { Pagination, Autoplay, Navigation, EffectCards } from 'swiper/modules'

window.onerror = (message, source, lineNumber, columnNumber, error) => {
  console.error('An error is present: ', error)
}
console.log('script loaded')
const handleMobileMenu = () => {

	const burgerButton = document.querySelector('header .header-wrapper-burger-button')
	const navLinks = document.querySelectorAll('.header-wrapper-burger-nav a')
	const menuIcons = burgerButton.querySelectorAll('img')
	if (!burgerButton) return
	const mobileNav = document.querySelector('header .header-wrapper-burger-nav')
	const contentWrapper = document.querySelector('.overflow-block')

	// menuIcons.forEach(icon => {
	//
	// 	icon.addEventListener('click', () => {
	//
	// 		burgerButton.classList.toggle('active')
	// 		mobileNav.classList.toggle('closed')
	// 		mobileNav.classList.toggle('opened')
	// 		contentWrapper.style.overflow = contentWrapper.style.overflow === 'hidden' ? 'unset' : 'hidden'
	// 		contentWrapper.style.height = contentWrapper.style.height === window.innerHeight + 'px' ? 'auto' : window.innerHeight + 'px'
	//
	// 	})
	//
	// })
	
	burgerButton.addEventListener('click', () => {
		burgerButton.classList.toggle('active')
		mobileNav.classList.toggle('closed')
		mobileNav.classList.toggle('opened')
		// contentWrapper.style.overflow = contentWrapper.style.overflow === 'hidden' ? 'unset' : 'hidden'
		contentWrapper.style.height = contentWrapper.style.height === window.innerHeight + 'px' ? 'auto' : window.innerHeight + 'px'

	})
	navLinks.forEach(item => {

		item.addEventListener('click', () => {
			// contentWrapper.style.overflow = contentWrapper.style.overflow === 'hidden' ? 'unset' : 'hidden'
			contentWrapper.style.height = contentWrapper.style.height === window.innerHeight + 'px' ? 'auto' : window.innerHeight + 'px'
			burgerButton.classList.remove('active')
			mobileNav.classList.toggle('closed')
			mobileNav.classList.toggle('opened')
		})

	})
}

const showModal = (title = '', text = '') => {
	const modal = document.querySelector('.modal-window')
	if (!modal) return

	const modalTitle = modal.querySelector('.modal-window-wrapper-title')
	const modalText = modal.querySelector('.modal-window-wrapper-text')
	modalTitle.innerText = title
	modalText.innerText = text
	modal.classList.toggle('hidden')
}
const allLinks = document.querySelectorAll('a')
if (allLinks) {
	allLinks.forEach(link => {
		link.addEventListener('click', () => {
			console.log('link.href', link.href)
			if (!link.href.length) {
				showModal('Coming soon...')
			}
		})
	})
}
const closeModal = () => {
	const closeModalButton = document.querySelector('.modal-window-wrapper-close')
	if (!closeModalButton) return
	closeModalButton.addEventListener('click', () => showModal())
}
export const createFormItemInstance = () => {
	const contactForm = document.querySelector('#contactForm')
	contactForm.querySelectorAll('.input-box').forEach(item => {
		const input = item.querySelector('input') || item.querySelector('textarea')
		input.addEventListener('change', function() {
			item.classList.remove('invalid')
			item.querySelector('.input-box-error').innerHTML = ''
		})
	})
}
function isValidEmail (email) {
	const accessForm = document.getElementById('earlyAccessForm')
	if (!accessForm) return
	let inputValid = true
	const input = accessForm.querySelector('.form-input')
	const emailInput = accessForm.querySelector('#accessRequestInput')
	if (emailInput.hasAttribute('required')) {
		if (emailInput.value.trim().length === 0) {
			input.classList.add('invalid')
			input.querySelector('.form-input-error').innerText = 'Please enter email'
			return false
		}
	}
	if (emailInput.getAttribute('type') === 'email') {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
		if (!emailRegex.test(email)) {
			input.classList.add('invalid')
			input.querySelector('.form-input-error').innerText = 'Invalid email'
			return false
		}
	}
	return inputValid
}
const validateInput = (input) => {

	if (input.hasAttribute('required')) {
		if (input.value.trim().length === 0) {
			return { isValid: false, message: input.getAttribute('required') }
		}
	}

	if (input.getAttribute('type') === 'email') {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

		if (!emailRegex.test(input.value)) {
			return { isValid: false, message: input.getAttribute('msg') }
		}
	}

	if (input.hasAttribute('minlength') && input.value.trim().length) {
		const minLength = Number(input.getAttribute('minlength'))

		if (minLength && input.value.trim().length < minLength) {
			return { isValid: false, message: input.getAttribute('minlengthmsg') }
		}
	}

	return { isValid: true }
}
const validateForm = () => {
	let isValidForm = true
	document.querySelector('#contactForm').querySelectorAll('.input-box').forEach(formItem => {
		const inputValidateData = validateInput(formItem.querySelector('input') || formItem.querySelector('textarea'))
		if (!inputValidateData.isValid) {
			formItem.classList.add('invalid')
			formItem.querySelector('.input-box-error ').innerText = inputValidateData.message
			isValidForm = false
		}
	})
	return isValidForm
}
const handleSendContactForm = () => {
	const contactForm = document.querySelector('.contact-form')
	if (!contactForm) return
	const formTitle = contactForm.getAttribute('data-title')
	const sendButton = contactForm.querySelector('#sendButton')
	const nameInput = contactForm.querySelector('#nameInput')
	const emailInput = contactForm.querySelector('#emailInput')
	const messageInput = contactForm.querySelector('#messageInput')
	createFormItemInstance()

	sendButton.addEventListener('click', (e) => {
		e.preventDefault()
		const formValid = validateForm()
		let text = `Form type: ${formTitle} \nUser name: ${nameInput.value}, \nuser email: ${emailInput.value}, \nmessage: ${messageInput.value}`
		if (formValid) {

			const options = {
				method: 'POST',
				url: 'https://api.telegram.org/bot6527323713:AAHzGjnfHtIo-ur-cGOYPFHQLr7RPhPSbHE/sendMessage',
				headers: {
					accept: 'application/json',
					'User-Agent': 'Telegram Bot SDK - (https://github.com/irazasyed/telegram-bot-sdk)',
					'content-type': 'application/json'
				},
				data: {
					chat_id: '-1002142004546',
					text: text,
					// parse_mode: 'Optional',
					disable_web_page_preview: false,
					disable_notification: false,
					reply_to_message_id: null
				}
			}
			axios
				.request(options)
				.then(function(response) {
					console.log(response.data)
					nameInput.value = ''
					emailInput.value = ''
					messageInput.value = ''
					showModal('THANK YOU!', 'We have receive your message. We will contact you as soon as possible')
				})
				.catch(function(error) {
					console.error(error)
					showModal('Error', 'Something went wrong. Please, try again later.')
				})
		} else {
			console.log('form invalid')
		}
	})
}

document.addEventListener('DOMContentLoaded', () => {
	handleMobileMenu()
	closeModal()
	handleSendContactForm()
})